/* This example requires Tailwind CSS v2.0+ */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const Modal = ({ children, open, onClose, title }: { children: JSX.Element; open?: boolean; onClose?: Function; title: string }) => {
  const close = () => {
    if (onClose) onClose()
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' static className='fixed z-10 inset-0 overflow-y-auto' open={open} onClose={close}>
          <div className='flex items-center justify-center min-h-screen pt-20 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white px-4 pt-3 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 w-full'>
                <div className='border-b border-gray-400 -mx-4'>
                  <button onClick={close} className='absolute top-0 right-0 cursor-pointer'>
                    <FontAwesomeIcon icon={faTimes} className='h-8 w-8 top-0 absolute right-0 mt-3 mr-4' />
                  </button>
                  <div className='text-center '>
                    <Dialog.Title as='h3' className='text-lg py-2 leading-6 font-medium text-gray-900'>
                      {title}
                    </Dialog.Title>
                    <div className='mt-2 w-full'></div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Modal
